function generateFullMenuList(data) {
  var appContainer = document.createElement('div');
  appContainer.classList.add('full-menu-list');

  var titleDiv = document.createElement('div');
  titleDiv.classList.add('main-title');
  titleDiv.textContent = '猜你想问:';
  appContainer.appendChild(titleDiv);

  var menuContainer = document.createElement('div');
  menuContainer.classList.add('menu');
  var menuListContainer = document.createElement('div');
  menuListContainer.classList.add('menu-list');
  if(data.length >= 1) {
    appContainer.appendChild(menuContainer);
    menuContainer.appendChild(menuListContainer);
  }

  var contentContainer = document.createElement('div');
  contentContainer.classList.add('content');
  appContainer.appendChild(contentContainer);

  var wrapperDOM = document.createElement('div');
  wrapperDOM.appendChild(appContainer);


  // 创建菜单项
  data.forEach(function (categoryData, index) {
    var menuItem = document.createElement('div');
    menuItem.classList.add('menu-item');
    if (index === 0) {
      menuItem.classList.add('active');
      menuItem.classList.add('fg-color');
    }
    menuItem.textContent = categoryData.category;
    menuItem.dataset.index = index;
    if (categoryData.category !== "default") {
      menuListContainer.appendChild(menuItem);
    }
  
    // 创建对应的内容区域
    var contentItem = document.createElement('div');
    contentItem.classList.add('content-item');
    if (index === 0) contentItem.classList.add('active');
    contentItem.dataset.index = index;
  
    categoryData.info.forEach(function (info) {
      var content = document.createElement('div');
      content.classList.add('fg-color');
  
      var textDiv = document.createElement('div');
      textDiv.classList.add('text');
      textDiv.textContent = info.content;
      content.appendChild(textDiv);
  
      var iArrow = document.createElement('i');
      iArrow.classList.add('icon-arrow-right');
      content.appendChild(iArrow);
  
      contentItem.appendChild(content);
    });
  
    contentContainer.appendChild(contentItem);
  });

  if(data.length >= 2) {
    var leftArrow = document.createElement('div');
    leftArrow.classList.add('left-arrow');
    leftArrow.classList.add("icon-arrow-right")
    menuContainer.prepend(leftArrow);
    var rightArrow = document.createElement('div');
    rightArrow.classList.add('right-arrow');
    rightArrow.classList.add("icon-arrow-right")
    menuContainer.appendChild(rightArrow);
  }

  return wrapperDOM.innerHTML;
}

module.exports  = generateFullMenuList;