var apis = require("../apis");
var _const = require("@/common/const");
var container_tpl = require("./indexTpl.html");
var item_tpl = require("./itemTpl.html");
var utils = require("@/common/utils");
var SELFSERVICE_PATH = _const.SELFSERVICE_PATH;

module.exports = function(){
	var container = $(_.template(container_tpl)());

	apis.getSelfServiceList()
	.then(function(data){
		_.each(data, function(item){
			if(item.iconType == "system"){
				item.icon = SELFSERVICE_PATH + item.icon;
			}
		});
		container.removeClass("hide");

		console.log(data)
		container.append(_.template(item_tpl)({
			selfService: data
		}));
		$(".right_service_href_cls").on("click", function(e){
			console.log($(this).attr("id"))
			var id = $(this).attr("id");
			apis.recordFaqClick(id,"self-service");
			var dataCon = $(this).attr("dataCon");
			var dataType = $(this).attr("dataType");
			console.log(dataCon)
			console.log(dataType)
			if(dataType =="message"){
				var sendBtn = document.querySelector(".em-widget-send-wrapper .em-widget-send");
				var textInput = document.querySelector(".em-widget-send-wrapper .em-widget-textarea");
				textInput.value = dataCon;
				$(sendBtn).removeClass("disabled");
				utils.trigger(sendBtn, "click");
			} else {
				window.open(dataCon);
			}
		})
	});

	// APIs
	this.$el = container;
	this.show = function(){
		
	};
};
