var modal, dialog;
var isIframeMode;
var isMobile = isMobileDevice();
var autoOpen;

function handler(popupConfig) {
  var url = popupConfig.url || "http://localhost:8008/webim/demos/dunhuang/order-popup.html";
  autoOpen = popupConfig.autoOpen;
  isIframeMode = window.top !== window.self;
  modal = createModal();

  // 将 popupConfig 中的配置信息传递给 iframe url
  var urlInfoConfig = popupConfig
  delete urlInfoConfig.url

  var params = "";
  for (var key in urlInfoConfig) {
    if (urlInfoConfig.hasOwnProperty(key)) {
      if (params !== "") {
        params += "&";
      }
      params += encodeURIComponent(key) + "=" + encodeURIComponent(urlInfoConfig[key].toString());
    }
  }

  if(params.length) {
    url += "?" + params;
  }
  
  dialog = renderDialog(url);

  var container = document.querySelector(".em-widget-box");

  // 点击 dialog 以外的区域关闭 dialog
  container.addEventListener("click", function (event) {
    var isClickInsidePopup = dialog.contains(event.target); // 检查点击的元素是否在 dialog 内部
    var isClickInsideModal = modal.contains(event.target); // 检查点击的元素是否在 dialog 内部

    if (!isClickInsidePopup && isClickInsideModal) {
      hide();
    }
  });

  iframeListenHandler();
  modal.appendChild(dialog);
  container.appendChild(modal);

  if (autoOpen) {
    show();
  } else {
    hide();
  }
}

function renderDialog(url) {
  dialog = document.createElement("div");
  dialog.className = "popup-dialog";
  dialog.style.width = "100%";
  if (isMobile) {
    dialog.style.height = "75%";
  } else {
    dialog.style.height = "417px";
  }
  dialog.style.padding = "0";
  dialog.style.margin = "0";
  dialog.style.border = "none";
  dialog.style.position = "absolute";
  dialog.style.backgroundColor = "#fff";
  dialog.style.bottom = "0";
  dialog.style.left = "0";
  dialog.style.zIndex = "10";
  dialog.style.borderRadius = "12px 12px 0 0";

  dialog.innerHTML =
    '<iframe src="' + url + '" style="width:100%;height:100%;border:none;"></iframe>';
  return dialog;
}

function createModal() {
  // 新建一个 Modal
  var modal = document.createElement("div");
  modal.className = "popup-modal";
  modal.style.width = "100%";
  modal.style.height = "100%";
  modal.style.position = "absolute";
  modal.style.top = "0";
  modal.style.left = "0";
  modal.style.zIndex = "10";
  modal.style.backgroundColor = "rgba(0,0,0,0.5)";
  return modal;
}

function iframeListenHandler() {
  window.addEventListener("message", function (event) {
    var data;
    try {
      data = JSON.parse(event.data);
    } catch (err) {
      return;
    }

    var type = data.type;
    var value = data.value;

    if (type === "POPUP_CLOSE") {
      hide();
    } else if (type == "POPUP_ORDER") {
      hide();
      window.sendText(value);
    }
  });
}

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function ifShouldOpenPopup(config) {
  if (config.popup && config.popup.switch) {
    handler(config.popup.url);
  }
}

function show() {
  dialog.style.display = "block";
  modal.style.display = "block";
}

function hide() {
  dialog.style.display = "none";
  modal.style.display = "none";
}

module.exports = {
  ifShouldOpenPopup: ifShouldOpenPopup,
  handler: handler,
  show:show,
  hide:hide,
};
